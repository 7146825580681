<template>
  <td
    class="StatusCell"
    :data-probability="modelValue"
  >
    <template v-if="isEditable">
      <button 
        :disabled="modelValue === 1"
        @click="$emit('update:modelValue', 1)"
      >In</button>
      <button 
        :disabled="modelValue === 0"
        @click="$emit('update:modelValue', 0)"
      >Out</button>
      <button   
        :disabled="modelValue === 0.5"
        @click="$emit('update:modelValue', 0.5)"
      >Maybe</button>
    </template>
    <template v-else-if="modelValue === 1">In</template>
    <template v-else-if="modelValue === 0">Out</template>
    <template v-else-if="modelValue === 0.5">Maybe</template>
  </td>
</template>

<script lang="ts" setup>
const modelValue = defineModel<number | null>({required: true})

withDefaults(defineProps<{
  isEditable?: boolean,
}>(), {
  isEditable: false,
})
</script>

<style lang="scss" scoped>
.StatusCell {
  position: relative;
  z-index: 0;

  &::before {
    position: absolute;
    inset: 0;
    z-index: -1;
    opacity: 0.5;
    content: "";
  }

  &[data-probability="1"]::before {
    background-color: limegreen;
  }

  &[data-probability="0.5"]::before {
    background-color: yellow;
  }

  &[data-probability="0"]::before {
    background-color: tomato;
  }
}
</style>