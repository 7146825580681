import AcceptRunInviteView from "@/views/AcceptRunInviteView.vue";
import ClaimPlayerView from "@/views/ClaimPlayerView.vue";
import IndexView from "@/views/IndexView.vue";
import { RouteRecordRaw } from "vue-router";

export enum RouteName {
  index = "index",
  runInviteAccept = "runInviteAccept",
  claimPlayer = "claimPlayer",
}

export const routes:Array<RouteRecordRaw> = [
  {
    name: RouteName.index,
    path: "/",
    component: IndexView,
  },
  {
    name: RouteName.runInviteAccept,
    path: "/run-invites/:runId/:playerId/accept",
    component: AcceptRunInviteView,
  },
  {
    name: RouteName.claimPlayer,
    path: "/players/:id/claim",
    component: ClaimPlayerView,
  },
]