<template>
  <progress v-if="!isLoaded" />
  <RouterView v-else />
</template>

<script lang="ts" setup>
import useAuthStore from '@/stores/useAuthStore';
import { onMounted, ref } from 'vue';

const {fetchCurrentUser} = useAuthStore()
const isLoaded = ref(false)

onMounted(async () => {
  await fetchCurrentUser()
  isLoaded.value = true
})
</script>

<style lang="scss" scoped>
</style>
