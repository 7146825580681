<template>
  <progress v-if="isFetchingRunInvite || isUpdatingStatus" />
  <section v-else-if="isNotPlayer">
    <p>You can’t update another player’s status, bruh</p>
  </section>
</template>

<script lang="ts" setup>
import { TablesInsert } from '@/lib/database/database.types';
import { supabaseClient } from '@/lib/database/supabase';
import { RouteName } from '@/lib/router/routes';
import useAuthStore from '@/stores/useAuthStore';
import { QueryData } from '@supabase/supabase-js';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const fetchRunInviteQuery = supabaseClient
  .from("run_invites")
  .select("*, player: players(*)")
type RunInviteWithPlayer = QueryData<typeof fetchRunInviteQuery>[0]

const router = useRouter()
const route = useRoute()
const authStore = useAuthStore()
const { currentUser } = storeToRefs(authStore)

const runInvite = ref<RunInviteWithPlayer | null>(null)
const status = ref<TablesInsert<"statuses"> | null>(null)

const isFetchingRunInvite = ref(false)
const isUpdatingStatus = ref(false)

onMounted(async () => {
  const runId = String(route.params.runId)
  const playerId = String(route.params.playerId)

  const probability = parseFloat(String(route.query.probability))
  if (!(probability >= 0 && probability <= 1)) return alert("Probability is missing or invalid")

  const {runInvite: _runInvite, error: fetchRunInviteError} = await fetchRunInvite({ runId, playerId })
  if (fetchRunInviteError) return alert(fetchRunInviteError.message)
  
  runInvite.value = _runInvite

  if (isNotPlayer.value) return;
  if (isNotYetAttachedToPlayer.value) {
    router.push({
      name: RouteName.claimPlayer,
      params: {
        id: playerId,
      },
      query: {
        runId,
        probability,
      }
    })
    return;
  }

  const {status: _status, error: updateStatusError} = await updateStatus({ 
    runId: _runInvite.run_id,
    playerId: _runInvite.player_id,
    probability,
  })
  status.value = _status

  if (updateStatusError) {
    return alert(updateStatusError.message)
  } else {
    alert("Status updated!")
    router.replace({ name: RouteName.index })
  }
})

const isNotPlayer = computed(() => {
  return currentUser.value && 
    runInvite.value?.player?.user_id && 
    currentUser.value.id !== runInvite.value.player.user_id
})

const isNotYetAttachedToPlayer = computed(() => {
  return currentUser.value && 
    currentUser.value.email && 
    currentUser.value.email === runInvite.value?.player?.email &&
    runInvite.value.player.user_id === null
})

async function fetchRunInvite({
  runId,
  playerId,
}: {
  runId: string,
  playerId: string,
}) {
  isFetchingRunInvite.value = true
  const {data: _runInvite, error: fetchRunInviteError} = await fetchRunInviteQuery
    .eq("run_id", runId)
    .eq("player_id", playerId)
    .limit(1)
    .maybeSingle()
  isFetchingRunInvite.value = false

  if (!_runInvite || fetchRunInviteError) {
    return { 
      runInvite: null,
      error: fetchRunInviteError || new Error("Run invite is missing") 
    }
  }

  return {
    runInvite: _runInvite,
    error: null,
  }
}

async function updateStatus({
  runId,
  playerId,
  probability,
}: {
  runId: string,
  playerId: string,
  probability: number,
}) {
  isUpdatingStatus.value = true
  const {data:status, error:updateStatusError} = await supabaseClient
    .from("statuses")
    .upsert({
      run_id: runId,
      player_id: playerId,
      probability,
    })
    .select()
    .limit(1)
    .maybeSingle()
  isUpdatingStatus.value = false

  if (updateStatusError) {
    return { 
      status: null,
      error: updateStatusError 
    }
  }

  return {
    status,
    error: null,
  }
}
</script>

<style lang="scss" scoped>
</style>