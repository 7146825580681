<template>
  <AuthView />
  <br>
  <ScheduleView />
</template>

<script lang="ts" setup>
import AuthView from '@/views/AuthView.vue';
import ScheduleView from '@/views/ScheduleView.vue';
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute()

onMounted(() => {
  if (route.query.error && route.query.error_description) {
    alert(route.query.error_description)
  }
})
</script>

<style lang="scss" scoped>
</style>