import { supabaseClient } from "@/lib/database/supabase";
import { SignInWithOAuthCredentials, SignInWithPasswordlessCredentials, User } from "@supabase/supabase-js";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export default defineStore("AuthStore", () => {
  const currentUser = ref<User | null>(null)

  const isSignedIn = computed(() => !!currentUser.value)

  async function fetchCurrentUser() {
    const {data:{user}} = await supabaseClient.auth.getUser();
    currentUser.value = user;
  }

  async function signInWithEmail(email:string, options:SignInWithPasswordlessCredentials["options"] = {}) {
    return supabaseClient.auth.signInWithOtp({
      email,
      options,
    })
  }

  async function signInWithGoogle(options:Omit<SignInWithOAuthCredentials, "provider">["options"] = {}) {
    return supabaseClient.auth.signInWithOAuth({
      provider: 'google',
      options,
    })
  }

  async function signOut() {
    const {error} = await supabaseClient.auth.signOut()

    if (!error) {
      currentUser.value = null
    }
  }

  return {
    currentUser,
    isSignedIn,
    
    fetchCurrentUser,
    signInWithEmail,
    signInWithGoogle,
    signOut,
  }
})