<template>
  <section>
    <template v-if="currentUser">Signed in as: {{ currentUser.email }}</template>
    &nbsp;
    <progress v-if="isAuthenticating" />
    <div
      v-else-if="!currentUser"
    >
      <p v-if="isOtpSent">Check your email for a sign in link</p>
      <div style="display: flex; gap: 8px">
        <form @submit="handleEmailSignIn">
          <input 
            v-model="email"
            type="email" 
            required
          >
          <button 
            type="submit"
          >Sign in</button>
        </form>
        &bull;
        <button 
          @click="handleGoogleSignIn"
        >Sign in with Google</button>
      </div>
    </div>
    <button 
      v-else
      @click="handleSignOut"
    >Sign out</button>
  </section>
</template>

<script lang="ts" setup>
import useAuthStore from '@/stores/useAuthStore';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';

const authStore = useAuthStore()
const {
  signInWithEmail,
  signInWithGoogle,
  signOut
} = authStore
const {currentUser} = storeToRefs(authStore)

const isAuthenticating = ref(false)
const email = ref("")
const isOtpSent = ref(false)

async function handleEmailSignIn() {
  isOtpSent.value = false
  isAuthenticating.value = true
  const {error} = await signInWithEmail(email.value)
  isAuthenticating.value = false

  if (!error) {
    isOtpSent.value = true
    email.value = ""
  } else {
    alert(`Error: ${error.message}`)
  }
}

async function handleGoogleSignIn() {
  isAuthenticating.value = true
  await signInWithGoogle()
  isAuthenticating.value = false
}

async function handleSignOut() {
  isAuthenticating.value = true
  await signOut()
  isAuthenticating.value = false
}
</script>

<style lang="scss" scoped>
</style>
