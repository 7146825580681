<template>
  <table>
    <thead>
      <tr>
        <th>Players</th>
        <th
          v-for="run in runs"
          :key="run.id!"
        >
          {{ format(parseISO(run.start_at!), "E, MMM d @ h:mmaaa") }}<br>
          <button 
            v-if="
              currentUser && 
              currentUser.id === run.user_id &&
              run.start_at > now
            "
            :disabled="isAnnouncingRun"
            @click="announceRun(run)"
          >Announce</button>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr 
        v-for="player in sortedPlayers"
        :key="player.id"
      >
        <td>
          <component :is="currentUser && player.user_id === currentUser.id ? 'strong' : 'span'">{{ player.name }}</component>
        </td>
        <StatusCell
          v-for="run in runs"
          :key="run.id!"
          :modelValue="getProbability(run, player)"
          :isEditable="run.start_at! > now && !!currentUser && player.user_id === currentUser.id"
          @update:modelValue="updateStatus({run, player, probability: $event})"
        />
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td />
        <td
          v-for="run in runsWithCounts"
          :key="run.id!"
        >
          <strong>
            <template v-if="run.in_count">{{ run.in_count }} in</template><template v-if="run.in_count && run.maybe_count">, </template>
            <template v-if="run.maybe_count">{{ run.maybe_count }} maybe</template>
            <template v-if="!run.in_count && !run.maybe_count">0 in</template>
          </strong>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script lang="ts" setup>
import StatusCell from '@/components/StatusCell.vue';
import { Tables } from '@/lib/database/database.types';
import { supabaseClient } from '@/lib/database/supabase';
import useAuthStore from '@/stores/useAuthStore';
import { RealtimeChannel, RealtimePostgresDeletePayload, RealtimePostgresInsertPayload, RealtimePostgresUpdatePayload } from '@supabase/supabase-js';
import { format, parseISO, subWeeks } from 'date-fns';
import { storeToRefs } from 'pinia';
import { MessageSendingResponse } from 'postmark/dist/client/models';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

const {currentUser} = storeToRefs(useAuthStore())
const now = ref(new Date().toISOString()) // TODO: extract now to be realtime

const runs = ref<Array<Tables<"runs">>>([])
const players = ref<Array<Tables<"players">>>([])
const statuses = ref<Array<Tables<"statuses">>>([])

const statusChannel = ref<RealtimeChannel | null>(null);
const statusMap = computed(():Map<string, Tables<"statuses">> => {
  return new Map(statuses.value.map((status) => [`${status.run_id}/${status.player_id}`, status]))
})

const isAnnouncingRun = ref(false)

supabaseClient.from("players").select().then(({data: _players}) => {
  if (_players) {
    players.value = _players
  }
})

supabaseClient.from("statuses").select().then(({data: _statuses}) => {
  if (_statuses) {
    statuses.value = _statuses
  }
})

supabaseClient
  .from("runs")
  .select()
  .gte("start_at", subWeeks(now.value, 1).toISOString())
  .order("start_at", {ascending: true})
  .then(({data: _runs}) => {
    if (_runs) {
      runs.value = _runs
    }
  })

onMounted(() => {
  statusChannel.value = supabaseClient
    .channel("statuses")
    .on("postgres_changes", { event: "INSERT", schema: "public", table: "statuses" }, handleStatusUpsert)
    .on("postgres_changes", { event: "UPDATE", schema: "public", table: "statuses" }, handleStatusUpsert)
    .on("postgres_changes", { event: "DELETE", schema: "public", table: "statuses" }, handleStatusDelete)
    .subscribe();
})

onBeforeUnmount(() => {
  statusChannel.value?.unsubscribe();
  statusChannel.value = null;
})

const runsWithCounts = computed(() => {
  return runs.value.map((run) => {
    return {
      ...run,
      ...statuses.value.filter((status) => status.run_id === run.id).reduce((acc, status) => {
        if (status.probability === 1) {
          acc.in_count++
        } else if (status.probability === 0) {
          acc.out_count++
        } else if (status.probability === 0.5) {
          acc.maybe_count++
        }

        return acc
      }, {
        in_count: 0,
        out_count: 0,
        maybe_count: 0,
      })
    }
  })
})

const sortedPlayers = computed(() => {
  return [...players.value].sort((a, b) => {
    if (currentUser.value && a.user_id === currentUser.value.id) return -1
    if (currentUser.value && b.user_id === currentUser.value.id) return 1

    return a.name.localeCompare(b.name)
  })
})

function getProbability(run:Tables<"runs">, player:Tables<"players">) {
  return statusMap.value.get(`${run.id}/${player.id}`)?.probability ?? null
}

async function announceRun(run:Tables<"runs">) {
  isAnnouncingRun.value = true
  
  const response = await fetch(`/api/runs/${run.id}/send`, {
    method: "POST",
  })
  const {sentEmails, error}:{sentEmails?: Array<MessageSendingResponse>, error?: Error} = await response.json()
  if (sentEmails) {
    alert(sentEmails.length > 0 ? `Sent ${sentEmails.length} emails` : `No emails sent. All players have already been contacted`)
  } else if (error) {
    alert(`Error: ${error.message}`)
  }
  
  isAnnouncingRun.value = false
}

function addOrReplaceStatus(status:Tables<"statuses">) {
  const existingStatus = statuses.value.find((_status) => _status.run_id === status.run_id && _status.player_id == status.player_id)

  if (existingStatus) {
    removeStatus(status)
  }

  statuses.value.push(status)
}

function removeStatus(status:Partial<Tables<"statuses">>) {
  const index = statuses.value.findIndex((_status) => _status.run_id === status.run_id && _status.player_id === status.player_id)
  
  if (index !== -1) {
    statuses.value.splice(index, 1)
  }
}

async function updateStatus({run, player, probability}:{run:Tables<"runs">, player:Tables<"players">, probability:number | null}) {
  if (probability === null) return;

  const {data: status, error} = await supabaseClient.from("statuses").upsert({
    run_id: run.id!,
    player_id: player.id,
    probability,
  }).select().limit(1).maybeSingle();

  if (status && !error) {
    addOrReplaceStatus(status)
  } else if (error) {
    alert(error.message)
  }
}

function handleStatusUpsert (payload:RealtimePostgresInsertPayload<Tables<"statuses">> | RealtimePostgresUpdatePayload<Tables<"statuses">>) {
  addOrReplaceStatus(payload.new)
}

function handleStatusDelete (payload:RealtimePostgresDeletePayload<Tables<"statuses">>) {
  removeStatus(payload.old)
}
</script>

<style lang="scss" scoped>
table {
  border-collapse: collapse;
}

th,
td {
  padding: 4px 8px;
  border: 1px solid lightgrey;
  text-align: center;

  &:first-child {
    text-align: left;
  }

  @media (prefers-color-scheme: dark) {
    border: 1px solid rgba(255, 255, 255, 0.25);
  }
}
</style>
